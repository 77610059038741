import React from 'react';
import { graphql, PageProps, Link } from 'gatsby';
import Layout from '../components/Layout';
import NotFoundPage from '../pages/404';
import BackIcon from '../images/ui/icon-back.inline.svg';
import { GatsbyImage } from 'gatsby-plugin-image';
import dateFormat from 'dateformat';
import { WorldsFairHead } from '../components/Head';

const Logs = ({ data: { post, logs } }: PageProps<Queries.PostTemplateQuery>) => {
    if (!post) {
        return <NotFoundPage />;
    }

    const {
        title,
        content,
        date,
        featuredImage,
    } = post;

    return (
        <Layout location="post" showSignup={true} seoData={post.seo}>
            {logs?.slug &&
                <Link to={`/${logs.slug}`} className="post-back-link" aria-label="Back to Logs">
                    <BackIcon />
                </Link>
            }
            <div className="post-meta">
                {featuredImage?.node?.gatsbyImage &&
                    <div className="post-meta__image">
                        <GatsbyImage alt={`${title} Featured Image`} image={featuredImage?.node?.gatsbyImage} />
                    </div>
                }
                <h1 className="post-meta__title">
                    {title}
                </h1>
                {date &&
                    <div className="post-meta__date">
                        {dateFormat(Date.parse(date), 'd mmmm yyyy')}
                    </div>
                }
            </div>

            <article dangerouslySetInnerHTML={{ __html: content || '' }}/>
        </Layout>
    );
}

export default Logs;

export const query = graphql`
    query PostTemplate ($id: Int!, $logsTemplateName: String!) {
        post: wpPost(databaseId: { eq: $id }) {
            featuredImage {
                node {
                    gatsbyImage(layout: FULL_WIDTH, width: 700, sizes: "700px")
                }
            }
            title
            content
            date
            ...SeoData
        }

        logs: wpPage(template: { templateName: { eq: $logsTemplateName }}) {
            slug
        }
    }
`

export const Head = () => <WorldsFairHead />